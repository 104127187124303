import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

// Initialize Firebase
//PROD CONFIG
const config = {
    apiKey: "AIzaSyB1NNh3nAVLFyhou5mH0g9t60r9ImcvkaQ",
    authDomain: "hik-portal.firebaseapp.com",
    projectId: "hik-portal",
    storageBucket: "hik-portal.appspot.com",
    messagingSenderId: "1022735862331",
    appId: "1:1022735862331:web:625c29bbf530fc4d132a1e",
    measurementId: "G-DGJK4B6NEY"
}

//UPDATE_BEFORE_BUILD. Disable dev config and enable prod config
//DEV CONFIG
// const config = {
//     apiKey: "AIzaSyC7FpOIJQoi-mmmEO2duC2x6f35LIkqDdA",
//     authDomain: "hik-portal-dev.firebaseapp.com",
//     projectId: "hik-portal-dev",
//     storageBucket: "hik-portal-dev.appspot.com",
//     messagingSenderId: "716239194021",
//     appId: "1:716239194021:web:fe0497d91ddb507b1b0a4c",
//     measurementId: "G-R1KKEXBETV"
// }

firebase.initializeApp(config);

export default firebase;